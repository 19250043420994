<template>
  <div v-if="user" class="w-full h-full">
    <Navigation :expand="expand" :user="user" :title="route" @toggleMenu="toggleMenu" ref="nav" />
      <Header :expand="expand" :user="user" :title="route" @toggleMenu="toggleMenu" />
      <div id="content" class="w-full h-full pt-20" :class="expand?'pl-64':'md:pl-16'">
        <div class="w-full h-full p-4">
          <HomeNavigation :user="user" v-if="route === 'Home'" />
          <Attendance :user="user" v-if="route === 'Anwesenheit'" />
          <MealPlanning :user="user" v-if="route === 'Essensplanung'" />
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Navigation from '@/components/Navigation.vue'
import HomeNavigation from '@/components/HomeNavigation.vue'
import Attendance from '@/components/Attendance.vue'
import MealPlanning from '@/components/Mealplanning.vue'
import API from '@/API'
export default {
  name: 'HomeView',
  components: {
    Navigation,
    Header,
    Attendance,
    HomeNavigation,
    MealPlanning,
  }, data() {
    return {
      route: this.$router.currentRoute.value.name,
      user: null,
      expand: true,
    }
  },
  methods: {
    toggleMenu() {
      this.expand = !this.expand
    }
  },
  created() {
    API.checkAuth((r, status) => {
      if (status === 200) {
        this.user = r
      }else if(status === 401){
        window.location=process.env.VUE_APP_LOGIN_URL
      }
    })
    // dont expand if screen is small
    if (window.innerWidth < 1024) {
      this.expand = false;
    }
  }
}
</script>
