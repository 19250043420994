<template>
  <div class="relative w-full h-full overflow-y-auto">
    <div class="bg-white rounded-lg py-4 pl-4 w-full">
      <span class="font-bold text-xl">Anwesenheitskontrolle</span><br>
      <select v-model="filter.project" @change="getAttendance()">
        <option value="">Bitte Auswählen</option>
        <option v-for="project in projects" :key="project.id" :value="project.id">{{ project.name }}</option>
      </select>
      <span class="block text-lg font-bold">Tag</span>
      <input type="date" class="mr-4" v-model="filter.date" @change="getAttendance()">
      <button v-if="filter.project != ''" @click="getAttendance()">Neu Laden</button>
    </div>
    <div class="bg-white rounded-lg py-4 pl-4 mt-4 min-w-fit" v-if="contracts.length > 0">
      <table class="w-full">
        <thead>
          <tr>
            <th>Name</th>
            <th>Klasse</th>
            <th>Erwartet</th>
            <th>Geht um</th>
            <th>Angemeldet</th>
            <th>Abgemeldet</th>
            <th>Entschuldigt</th>
            <th>Kommentar</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><input class="border-2 border-gray-200 px-2 py-1" v-model="filter.name" type="text" placeholder="Filter" @input="filterContracts()"></td>
            <td><input class="border-2 border-gray-200 px-2 py-1" v-model="filter.class" type="text" placeholder="Filter" @input="filterContracts()"></td>
            <td><select v-model="filter.attendance.expected" @change="filterContracts()">
                <option value="">Filter</option>
                <option value="true">Ja</option>
                <option value="false">Nein</option>
              </select></td>
              <td></td>
              <td><select v-model="filter.attendance.started" @change="filterContracts()">
                <option value="">Filter</option>
                <option value="true">Ja</option>
                <option value="false">Nein</option>
              </select></td>
              <td><select v-model="filter.attendance.ended" @change="filterContracts()">
                <option value="">Filter</option>
                <option value="true">Ja</option>
                <option value="false">Nein</option>
              </select></td>
              <td><select v-model="filter.attendance.excused" @change="filterContracts()">
                <option value="">Filter</option>
                <option value="true">Ja</option>
                <option value="false">Nein</option>
              </select></td>
              <td><input class="border-2 border-gray-200 px-2 py-1" v-model="filter.attendance.comment" type="text" placeholder="Filter" @input="filterContracts()"></td>
          </tr>
          <tr v-for="contract in filteredContracts" :key="contract.id">
            <td class="font-bold">{{ contract.name }}</td>
            <td>{{ contract.class }}</td>
            <td class="!text-center"><input type="checkbox" class="inline-block" v-model="contract.attendance.expected" @change="setAttendance(contract)"></td>
            <td><input type="time" class="w-36 border-b border-gray-300 bg-inherit" v-model="contract.attendance.plannedEnd"  @change="setAttendance(contract)"></td>
            <td class="!text-center"><input type="checkbox" class="inline-block" v-model="contract.attendance.started" @change="setAttendance(contract)"><div v-if="contract.attendance.startTime">({{ contract.attendance.startTime }})</div></td>
            <td class="!text-center"><input type="checkbox" class="inline-block" v-model="contract.attendance.ended" @change="setAttendance(contract)"><div v-if="contract.attendance.endTime">({{ contract.attendance.endTime }})</div></td>
            <td class="!text-center"><input type="checkbox" class="inline-block" v-model="contract.attendance.excused" @change="setAttendance(contract)"></td>
            <td><input  class="w-full border-b border-gray-300 bg-inherit" v-model="contract.attendance.comment"  @change="setAttendance(contract)"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped>
table {
  max-width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
  /* Set the background color for even rows */
}
</style>

<script>
// @ is an alias to /src
import API from "@/API"
export default {
  name: 'Attendance',

  data() {
    return {
      projects: [],
      filter: {
        project: "",
        date: new Date().toISOString().slice(0, 10),
        name: "",
        class: "",
        attendance: {
          expected: "",
          started: "",
          ended: "",
          excused: ""
        }
      },
      filteredContracts: [],
      contracts: []
    }
  },
  methods: {
    setAttendance(contract) {
      let vm = this
      API.setAttendance({
        id: contract.id,
        attendance: contract.attendance,
        date: this.filter.date.split("-")[2]
      }, (r, status) => {
      })
    },
    getProjects() {
      let vm = this;
      API.getProjects((r, status) => {
        if (status === 200) {
          vm.projects = r
        }
      })
    },
    filterContracts() {
      let vm = this
      this.filteredContracts = this.contracts.filter(item => {
        if (item.name.toLowerCase().indexOf(vm.filter.name.toLowerCase()) < 0) {
          return false
        }
        if (item.class.toLowerCase().indexOf(vm.filter.class.toLowerCase()) < 0) {
          return false
        }
        if(vm.filter.attendance.expected == "true" && !item.attendance.expected) return false
        if(vm.filter.attendance.ended == "true" && !item.attendance.ended) return false
        if(vm.filter.attendance.started == "true" && !item.attendance.started) return false
        if(vm.filter.attendance.excused == "true" && !item.attendance.excused) return false
        if(vm.filter.attendance.ended == "true" && !item.attendance.ended) return false
        if(vm.filter.attendance.ended == "false" && item.attendance.ended) return false
        if(vm.filter.attendance.started == "false" && item.attendance.started) return false
        if(vm.filter.attendance.excused == "false" && item.attendance.excused) return false
        if(vm.filter.attendance.ended == "false" && item.attendance.ended) return false
        if(vm.filter.attendance.expected == "false" && item.attendance.expected) return false
        return true
      })
    },

    getAttendance() {
      let vm = this;
      API.getAttendance(vm.filter.project, vm.filter.date, (r, status) => {
        if (status === 200) {
          vm.contracts = r
          setTimeout(() => {
            vm.filterContracts()
          }, 50)

        }
      })
    }
  },
  created() {
    this.getProjects()
  }
}
</script>