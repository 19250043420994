<template>
  <div>
    <div
      v-if="user.Attendance > 0"
      class="bg-white mr-2 rounded-lg shadow-lg p-4 text-center inline-block w-72 cursor-pointer"
      onclick="window.location.href='/attendance'">
      <font-awesome-icon
        icon="person-circle-check"
        class="text-6xl text-primary"
      /><br />
      <span class="text-2xl font-bold">Anwesenheit</span><br />
      <span class="inline-block w-full text-left"
        >Prüfe die Anwesenheit deiner Gruppe</span
      >
    </div>
    <div
      v-if="user.Mealplan > 0"
      class="bg-white mr-2 rounded-lg shadow-lg p-4 text-center inline-block w-72 cursor-pointer"
      onclick="window.location.href='/mealplanning'"
    >
      <font-awesome-icon icon="utensils" class="text-6xl text-primary" /><br />
      <span class="text-2xl font-bold">Essensplanung</span><br />
      <span class="inline-block w-full text-left"
        >Lade den Speiseplan für die kommenden Wochen hoch</span
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Cards",
  props: ["user"],
};
</script>