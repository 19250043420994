import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './index.css'
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

import { faArrowLeft, faBars, faCow, faCreditCard, faEnvelope, faHome, faIceCream, faLeaf, faPersonCircleCheck, faQrcode, faRightFromBracket, faUtensils, faXmark, faPiggyBank, faFishFins, faDrumstickBite, faLock } from '@fortawesome/free-solid-svg-icons';

library.add(faHome, faBars, faArrowLeft, faRightFromBracket, faCreditCard, faEnvelope, faLeaf, faQrcode, faPersonCircleCheck, faUtensils, faIceCream, faXmark, faCow, faLock, faPiggyBank,faFishFins, faLeaf, faDrumstickBite)

let app=createApp(App)
app.config.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false

app.component("font-awesome-icon", FontAwesomeIcon).use(router).use(ToastPlugin).mount("#app");// main.js
