function get(path, callback) {
    var req = new XMLHttpRequest()
    req.open("GET", process.env.VUE_APP_BE_URL + path)
    req.setRequestHeader('content-type', 'application/json')
    req.withCredentials = true
    req.redirect = "manual";
    req.addEventListener('load', function (event) {
        try {
            let response = JSON.parse(req.responseText)
            callback(response, req.status)
        } catch (e) {
            callback(false, req.status)
        }
    })
    req.onerror = function (event) {
        callback(false, false)
    }
    req.send()
}
function change(path, method, data, callback, withCredentials=true) {
    var req = new XMLHttpRequest()
    req.open(method, process.env.VUE_APP_BE_URL + path)
    req.setRequestHeader('content-type', 'application/json')
    req.withCredentials = withCredentials
    req.addEventListener('load', function (event) {
        try {
            let response = JSON.parse(req.responseText)
            callback(response, req.status)
        } catch (e) {
            callback(false, req.status)
        }
    })
    req.onerror = function (event) {
        callback(false, false)
    }
    req.send(JSON.stringify(data))
}

function post(path, data, callback, withCredentials=true) {
    change(path, "POST", data, callback, withCredentials)
}
function put(path, data, callback, withCredentials=true) {
    change(path, "PUT", data, callback, withCredentials)
}

function del(path, data, callback, withCredentials=true) {
    change(path, "DELETE", data, callback, withCredentials)
}

module.exports = {
    get,
    change,
    post,
    put,
    del
}